import OrganisationsAndUsers from '@/views/admin/OrganisationsAndUsers.vue';
import ChargeParks from '@/views/admin/ChargeParks.vue';
import ChargeParkDetails from '@/views/admin/ChargeParkDetails.vue';
import HardwareSystems from '@/views/admin/HardwareSystems.vue';
import RfidTags from '@/views/admin/RfidTags.vue';
import Whitelists from '@/views/admin/Whitelists.vue';
import WhitelistDetails from '@/views/admin/WhitelistDetails.vue';
import Updates from '@/views/admin/Updates.vue';
import AcceptDpaView from '@/views/auth/AcceptDpaView.vue';
import Contacts from '@/views/admin/Contacts.vue';
import Alerts from '@/views/admin/Alerts.vue';
import Reports from '@/views/admin/Reports.vue';
import MediaLibrary from '@/views/admin/MediaLibrary.vue';
import Playlists from '@/views/admin/Playlists.vue';
import ControlPanel from '@/views/admin/HardwareSystem.vue';
import Tariffs from '@/views/admin/Tariffs.vue';
import TariffDetails from '@/views/admin/TariffDetails.vue';
import DataExports from '@/views/admin/DataExports.vue';
import DashboardView from '@/views/admin/DashboardView.vue';
import Downtimes from '@/views/admin/Downtimes.vue';

import { createRouter, createWebHistory } from 'vue-router';
import {
  ORGANISATIONS_AND_USERS,
  CHARGE_PARKS,
  HARDWARE_SYSTEMS,
  RFID_TAGS,
  ROUTES,
  WHITELISTS,
  UPDATES,
  DASHBOARD,
  ALERTS,
  CONTACTS,
  REPORTS,
  MEDIA_LIBRARY,
  PLAYLISTS,
  DOWNTIMES,
  USER_PREFERENCES,
  TARIFFS,
  DATA_EXPORTS,
  BRANDING,
  CHARGING_SESSIONS,
} from '@/utils/routeNames';
import { Feature, Role } from '@/utils/constants';

import 'vue-router';
import Brandings from '@/views/admin/Brandings.vue';
import BrandingDetail from '@/views/admin/BrandingDetail.vue';
import UserPreferences from '@/views/admin/UserPreferences.vue';
import AuthError from '@/views/auth/AuthError.vue';
import ChargingSessionsView from '@/views/admin/ChargingSessionsView.vue';

declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean;
    requiredFeatures?: number[];
    requiredRoles?: number[];
    title?: string;
  }
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: `/${DASHBOARD}`,
      name: 'Dashboard',
      component: DashboardView,
      meta: {
        requiresAuth: true,
        requiredFeatures: [Feature.DASHBOARD],
        requiredRoles: [Role.ASSET_VIEWER],
        title: 'Dashboard',
      },
    },
    {
      path: `/${ORGANISATIONS_AND_USERS}`,
      name: 'OrganizationsAndUsers',
      component: OrganisationsAndUsers,
      meta: { requiresAuth: true, requiredFeatures: [], requiredRoles: [] },
    },
    {
      path: `/${CHARGE_PARKS}`,
      name: 'ChargeParks',
      component: ChargeParks,
      meta: {
        requiresAuth: true,
        requiredFeatures: [Feature.SITES],
        requiredRoles: [Role.ASSET_VIEWER],
      },
    },
    {
      path: `/${CHARGE_PARKS}/:id`,
      name: 'ChargeParkDetails',
      component: ChargeParkDetails,
      meta: {
        requiresAuth: true,
        requiredFeatures: [Feature.SITES],
        requiredRoles: [Role.ASSET_VIEWER],
        showBackButton: true,
      },
    },
    {
      path: `/${HARDWARE_SYSTEMS}`,
      name: 'HardwareSystems',
      component: HardwareSystems,
      meta: {
        requiresAuth: true,
        requiredFeatures: [],
        requiredRoles: [Role.ASSET_VIEWER],
      },
    },
    {
      path: `/${HARDWARE_SYSTEMS}/:id`,
      name: 'HardwareSystemDetails',
      component: ControlPanel,
      meta: {
        requiresAuth: true,
        requiredFeatures: [],
        requiredRoles: [Role.ASSET_VIEWER],
        showBackButton: true,
      },
    },
    {
      path: `/${DOWNTIMES}`,
      name: 'Downtimes',
      component: Downtimes,
      meta: {
        requiresAuth: true,
        requiredFeatures: [],
        requiredRoles: [Role.ASSET_OPERATOR],
      },
    },
    {
      path: `/${RFID_TAGS}`,
      name: 'RfidTags',
      component: RfidTags,
      meta: {
        requiresAuth: true,
        requiredFeatures: [Feature.WHITELIST],
        requiredRoles: [Role.WHITELIST_VIEWER],
      },
    },
    {
      path: `/${WHITELISTS}`,
      name: 'Whitelists',
      component: Whitelists,
      meta: {
        requiresAuth: true,
        requiredFeatures: [Feature.WHITELIST],
        requiredRoles: [Role.WHITELIST_VIEWER],
      },
    },
    {
      path: `/${WHITELISTS}/:id`,
      name: 'WhitelistDetails',
      component: WhitelistDetails,
      meta: {
        requiresAuth: true,
        requiredFeatures: [Feature.WHITELIST],
        requiredRoles: [Role.WHITELIST_VIEWER],
        showBackButton: true,
      },
    },
    {
      path: `/${TARIFFS}`,
      name: 'Tariffs',
      component: Tariffs,
      meta: {
        requiresAuth: true,
        requiredFeatures: [Feature.TARIFFS],
        requiredRoles: [Role.TARIFF_ADMIN],
      },
    },
    {
      path: `/${CHARGING_SESSIONS}`,
      name: 'ChargingSessions',
      component: ChargingSessionsView,
      meta: {
        requiresAuth: true,
        requiredFeatures: [],//[Feature.CHARGING_SESSIONS],
        requiredRoles: [Role.ASSET_VIEWER],
      },
    },
    {
      path: `/${CHARGING_SESSIONS}/:id`,
      name: 'ChargingSessionsDetails',
      component: ChargingSessionsView,
      meta: {
        requiresAuth: true,
        showBackButton: true,
        requiredFeatures: [],//[Feature.CHARGING_SESSIONS],
        requiredRoles: [Role.ASSET_VIEWER],//[Role.CHARGING_SESSIONS_ADMIN],
      },
    },
    {
      path: `/${TARIFFS}/:id`,
      name: 'TariffDetails',
      component: TariffDetails,
      meta: {
        requiresAuth: true,
        showBackButton: true,
        requiredFeatures: [Feature.TARIFFS],
        requiredRoles: [Role.TARIFF_ADMIN],
      },
    },
    {
      path: `/${UPDATES}/`,
      name: 'Updates',
      component: Updates,
      meta: {
        requiresAuth: true,
        requiredFeatures: [Feature.ADMINISTRATION],
        requiredRoles: [],
      },
    },
    {
      path: `/${ALERTS}/`,
      name: 'Alerts',
      component: Alerts,
      meta: {
        requiresAuth: true,
        requiredFeatures: [Feature.ACTIVE_MONITORING],
        requiredRoles: [Role.ACTIVE_MONITORING_ADMIN],
      },
    },
    {
      path: `/${REPORTS}/`,
      name: 'Reports',
      component: Reports,
      meta: {
        requiresAuth: true,
        requiredFeatures: [Feature.REPORTS],
        requiredRoles: [Role.REPORT_ADMIN],
      },
    },
    {
      path: `/${CONTACTS}/`,
      name: 'Contacts',
      component: Contacts,
      meta: {
        requiresAuth: true,
        requiredFeatures: [Feature.ACTIVE_MONITORING],
        requiredRoles: [Role.ACTIVE_MONITORING_ADMIN],
      },
    },
    {
      path: `/${MEDIA_LIBRARY}`,
      name: 'MediaLibrary',
      component: MediaLibrary,
      meta: {
        requiresAuth: true,
        requiredFeatures: [Feature.CONTENT_MANAGEMENT],
        requiredRoles: [Role.CONTENT_ADMIN],
      },
    },
    {
      path: `/${PLAYLISTS}`,
      name: 'Playlists',
      component: Playlists,
      meta: {
        requiresAuth: true,
        requiredFeatures: [Feature.CONTENT_MANAGEMENT],
        requiredRoles: [Role.CONTENT_ADMIN],
      },
    },
    {
      path: `/${DATA_EXPORTS}`,
      name: 'DataExports',
      component: DataExports,
      meta: {
        requiresAuth: true,
        requiredFeatures: [Feature.DATA_EXPORTS],
        requiredRoles: [Role.ASSET_VIEWER],
      },
    },
    {
      path: `/${BRANDING}`,
      name: 'Branding',
      component: Brandings,
      meta: {
        requiresAuth: true,
        requiredFeatures: [Feature.CUSTOM_UI],
        requiredRoles: [Role.CONTENT_ADMIN],
      },
    },
    {
      path: `/${BRANDING}/:id`,
      name: 'BrandingDetails',
      component: BrandingDetail,
      meta: {
        requiresAuth: true,
        requiredFeatures: [],
        requiredRoles: [],
        showBackButton: true,
      },
    },
    {
      path: `/${USER_PREFERENCES}`,
      name: 'UserPreferences',
      component: UserPreferences,
      meta: {
        requiresAuth: true,
        requiredFeatures: [],
        requiredRoles: [],
      },
    },
    {
      path: `/${ROUTES.DPA}`,
      name: 'Dpa',
      component: AcceptDpaView,
    },
    {
      path: `/${ROUTES.AUTH_ERROR}`,
      name: 'AuthError',
      component: AuthError,
    },
    { path: '/:pathMatch(.*)*', redirect: `/${DASHBOARD}` },
  ],
});

export default router;
