<template>
  <div class="telemetry-history-container">
    <div class="chart-header">
      <p class="label">{{ t('dashboard.telemetry.chart.title') }}</p>
      <div class="interactables">
        <button :disabled="loading" @click="refresh">
          <i v-if="loading" class="pi pi-spin pi-spinner"></i>
          <i v-else class="pi pi-refresh"></i>
        </button>
        <button @click="close">
          <i class="pi pi-times"></i>
        </button>
      </div>
    </div>

    <div class="chart-footer" role="radiogroup">
      <div class="w-full">
        <MultiSelect
          v-model="selectedTelemetries"
          :options="availTelemetryChannels"
          optionLabel="name"
          optionValue="path"
          :placeholder="t('hardwareSystem.selectTelemetryChannel')"
          class="w-full mb-4"
        >
          <template #value="slotProps">
            <div v-if="slotProps.value.length > 0">
              <span v-if="selectedTelemetries.length <= 5">
                <span
                  v-for="(tItem, index) in slotProps.value"
                  :key="index"
                  class="inline-block mr-2"
                >
                  {{ availTelemetryChannels?.find((item: any) => item.path == tItem)?.name
                  }}{{ index + 1 !== selectedTelemetries.length ? ',' : '' }}
                </span>
              </span>
              <span v-else-if="selectedTelemetries.length > 5">
                {{ selectedTelemetries.length }} channels selected
              </span>
            </div>
            <span v-else class="custom-value">{{ $t('organisation.availableFeatures') }}</span>
          </template>
          <template #option="slotProps">
            <span class="pr-4">{{ slotProps.option.name }}</span>
            <i
              class="pi pi-info-circle text-neutral-500 cursor-default"
              v-tooltip.right="{
                value: `
                  <span class='p-2'>${$t(
                    `organisation.featureDescription.${slotProps.option.id}`
                  )}</span>`,
                escape: true,
                class: 'custom-tooltip',
              }"
            ></i>
          </template>
        </MultiSelect>
        <div v-if="selectedTelemetries.length > 0">
          <div v-for="(tItem, index) in selectedTelemetries" :key="index" class="inline-block mr-2">
            <div
              data-cy="organisation-feature"
              class="custom-chip cursor-pointer"
              :style="`background-color: ${getColorForIndex(selectedTelemetries.findIndex((item: any)=>tItem == item))};`"
              @click="unselectTelemetryOption(tItem)"
            >
              <span
                >{{ availTelemetryChannels?.find((item: any) => item.path == tItem)?.name }}
              </span>
              <i class="pi pi-times text-xs font-bold"></i>
            </div>
          </div>
        </div>
      </div>
      <DateTimeInput
        v-if="!props.startTime"
        data-cy="event-history-time-from"
        ref="startDateTimeCalendar"
        class="w-1/2 h-8 mr-1"
        v-model="startTime"
        :maxDateTime="maxTimeFrom"
        :stepMinute="10"
        :placeholder="t('chargePark.timeFrom')"
      />
      <DateTimeInput
        v-if="!props.endTime"
        data-cy="event-history-time-to"
        ref="endDateTimeCalendar"
        class="w-1/2 h-8 ml-1"
        v-model="endTime"
        :minDateTime="minTimeTo"
        :maxDateTime="maxTimeTo"
        :stepMinute="10"
        :placeholder="t('chargePark.timeTo')"
      />
    </div>
    <div v-if="loading" class="message-container loading-container">
      <i class="pi pi-spin pi-spinner" />
      <p>{{ t('loading') }}</p>
    </div>
    <div v-else-if="error" class="message-container error-container">
      <p>{{ error }}</p>
    </div>
    <div
      v-else-if="emptyData"
      class="message-container nodata-container"
      :data-cy="`multi-telemetry-no-data`"
    >
      <exclamation-triangle-icon :style="{ height: '1rem', width: '1rem' }" />
      <p>{{ t('dashboard.telemetry.chart.nodata') }}</p>
    </div>
    <template v-else>
      <telemetry-fused-chart
        :data="data"
        :start-unix-epoch="rangeStart"
        :end-unix-epoch="rangeEnd"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import type { AvailableTelemetryOption, HardwareSystem } from '@/models/hardwareSystems.model';
import { computed, onMounted, ref, watch } from 'vue';
import { ExclamationTriangleIcon } from '@heroicons/vue/24/solid';

import Chip from 'primevue/chip';
import { use } from 'echarts/core';
import { DataZoomComponent } from 'echarts/components';
use([DataZoomComponent]);
import MultiSelect from 'primevue/multiselect';

import { useI18n } from 'vue-i18n';
import type { FusedDataResponse } from '@/stores/admin/controlPanel/controlPanel.types';
import { fetchHistoricalTelemetryFusedData } from '@/stores/admin/controlPanel/controlPanel.api';

import { fetchAvailableTelemetryChannels } from '@/stores/admin/hardwareSystems/hardwareSystems.api';
import TelemetryFusedChart from '@/components/hardwareSystems/controlPanel/widgets/telemetry/TelemetryFusedChart.vue';
import DateTimeInput from '@/components/common/time/DateTimeInput.vue';
import { colorsPallette } from '@/utils/colors';
const availTelemetryChannels = ref<AvailableTelemetryOption[]>();

const defaultShownOriginalUnits = ['mA', 'W'];

const { t } = useI18n();
const props = defineProps<{
  systemId: HardwareSystem['id'];
  chargePointId?: number; // TODO move this with availableTelemetry to parent
  startTime: string;
  endTime: string;
}>();
const unselectTelemetryOption = (telemetryID: string) => {
  selectedTelemetries.value.splice(
    selectedTelemetries.value.findIndex((item) => item === telemetryID),
    1
  );
};
const getColorForIndex = (index: number) => {
  return colorsPallette[index % colorsPallette.length];
};
const emit = defineEmits(['close']);
const selectedTelemetries = ref<string[]>([]);
const startTime = ref<string>(new Date(Date.now() - 60 * 60 * 24 * 7 * 1000).toISOString());
const endTime = ref<string>(new Date().toISOString());
const rangeStart = computed(() =>
  startTime.value ? new Date(startTime.value).valueOf() / 1000 : Date.now() / 1000 - 1000 * 60 * 60
);
const rangeEnd = computed(() =>
  endTime.value ? new Date(endTime.value).valueOf() / 1000 : Date.now() / 1000
);

const maxTimeFrom = computed(() => {
  if (!endTime.value) return new Date().toISOString();
  return endTime.value;
});
const maxTimeTo = computed(() => {
  return new Date().toISOString();
});
const minTimeTo = computed(() => {
  if (!startTime.value) return undefined;
  return startTime.value;
});

const loading = ref(true);
const emptyData = ref(false);

const data = ref<FusedDataResponse | undefined>(undefined);
const error = ref<string | undefined>(undefined);

function hasData(res: FusedDataResponse) {
  const xHasData = res && res.x.data.length > 0;
  const yHasData = res.y.length > 0;
  let anyYItemHasData = false;
  for (var i = 0; i < res.y.length; i++) {
    if (res.y[i].data.length > 0) anyYItemHasData = true;
  }
  return xHasData && yHasData && anyYItemHasData;
}

const fetchData = async () => {
  let paths = selectedTelemetries.value.map((path) => {
    return { systemId: props.systemId, path: path };
  });

  loading.value = true;
  error.value = undefined;
  emptyData.value = false;

  await fetchHistoricalTelemetryFusedData({
    paths: paths,
    start: rangeStart.value,
    end: rangeEnd.value,
    aggregation: { type: 'maxPoints', method: 'subSample', value: 200 },
  })
    .then((response) => {
      if (!hasData(response)) emptyData.value = true;
      data.value = response;
    })
    .finally(() => {
      loading.value = false;
    });
};

const refresh = async () => await fetchData();
const close = () => emit('close');

onMounted(async () => {
  if (props.startTime) startTime.value = props.startTime;
  if (props.endTime) endTime.value = props.endTime;
  availTelemetryChannels.value = await fetchAvailableTelemetryChannels(props.systemId, {
    startTime: startTime.value,
    endTime: endTime.value,
  });
  selectedTelemetries.value = availTelemetryChannels.value
    .filter((item) => {
      //return defaultShownOriginalUnits.includes(item.original_unit) &&
      return item.path.includes('chargePoint' + props.chargePointId);
    })
    .map((item) => item.path);
});

watch(startTime, fetchData);
watch(endTime, fetchData);

watch(selectedTelemetries, fetchData, { deep: true });
</script>

<style scoped lang="scss">
div.telemetry-history-container {
  box-sizing: border-box;
  margin: 0.5rem;
  position: relative;
  border: 1px solid var(--gray-200);
  border-radius: var(--rounded-md);
}

div.chart-header {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid var(--gray-200);
  padding: 0.5rem;
  background-color: var(--gray-50);
}

div.chart-footer {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid var(--gray-200);
  padding: 0.5rem;
  background-color: var(--gray-50);
}

div.interactables {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  align-items: center;
  margin-left: auto;
}

.chart {
  width: 100%;
  height: 400px;
  z-index: 0;
}

div.message-container {
  display: flex;
  padding: 1rem;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  align-items: center;
  min-height: 250px;
}

div.error-container {
  background-color: var(--gray-50);
  color: var(--red-primary);
}

div.nodata-container {
  background-color: var(--gray-50);
  color: var(--gray-500);
}

div.timerange-selector {
  border: 1px solid var(--gray-200);
  border-radius: var(--rounded-md);
  height: 2rem;
  overflow: hidden;
}

p.label {
  color: var(--gray-500);
}

input[type='radio'] {
  display: none;
}

div.enum-list {
  display: grid;
  border-collapse: collapse;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  overflow-x: auto;
  overflow-y: clip;

  background-color: var(--gray-50);
  border-bottom: 1px solid var(--gray-200);

  div.enum-item {
    display: flex;
    gap: 1rem;
    flex-grow: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    margin: -1px;

    border-right: 1px solid var(--gray-200);
    border-bottom: 1px solid var(--gray-200);

    p {
      margin: 0;
      padding: 0;
    }

    &:nth-child(3n) {
      border-right: none;
      margin: 0;
    }
  }
}

label {
  display: inline-flex;
  align-items: center;
  padding: 0 0.5rem;
  height: 100%;
  background-color: var(--gray-0);

  &.disabled {
    cursor: not-allowed;
  }

  &:not(.selected).disabled {
    color: var(--gray-300);
  }

  &:not(.selected):not(.disabled):hover {
    cursor: pointer;
    background-color: var(--gray-100);
  }

  &.selected {
    background-color: var(--green-primary);
    color: var(--gray-0);
  }
}

label:not(:last-child) {
  border-right: 1px solid var(--gray-200);
}

button {
  border-radius: var(--rounded-md);
  height: 2rem;
  width: 2rem;
  border: 1px solid var(--gray-200);
  background-color: var(--gray-0);

  &:hover {
    cursor: pointer;
    background-color: var(--gray-100);
  }
}
</style>
