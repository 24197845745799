import i18n from '@/i18n';
import {
  ORGANISATIONS_AND_USERS,
  CHARGE_PARKS,
  HARDWARE_SYSTEMS,
  RFID_TAGS,
  WHITELISTS,
  UPDATES,
  DASHBOARD,
  ALERTS,
  REPORTS,
  CONTACTS,
  MEDIA_LIBRARY,
  PLAYLISTS,
  DOWNTIMES,
  TARIFFS,
  BRANDING,
  DATA_EXPORTS,
  CHARGING_SESSIONS,
} from '@/utils/routeNames';

import { Feature, Role } from '@/utils/constants';

export type NavigationItem = {
  name: string;
  path: string;
  featureName: Feature | number | null;
  allowedRoles: Role[];
};

export type NavigationGroup = {
  name: string;
  items: NavigationItem[];
};

export const categories: NavigationGroup[] = [
  {
    name: i18n.global.t('sidebar.general'),
    items: [
      {
        name: i18n.global.t('myDashboardLink'),
        path: `/${DASHBOARD}`,
        featureName: Feature.DASHBOARD,
        allowedRoles: [Role.ASSET_VIEWER],
      },
    ],
  },
  {
    name: i18n.global.t('sidebar.infrastructure'),
    items: [
      {
        name: i18n.global.t('chargeParks'),
        path: `/${CHARGE_PARKS}`,
        featureName: Feature.SITES,
        allowedRoles: [Role.ASSET_VIEWER],
      },
      {
        name: i18n.global.t('hardwareSystem.systems'),
        path: `/${HARDWARE_SYSTEMS}`,
        featureName: null,
        allowedRoles: [Role.ASSET_VIEWER],
      },
      {
        name: i18n.global.t('chargingSessions.title'),
        path: `/${CHARGING_SESSIONS}`,
        featureName: null,
        allowedRoles: [Role.ASSET_VIEWER],
      },
      // {
      //   name: i18n.global.t('downtimes.title'),
      //   path: `/${DOWNTIMES}`,
      //   featureName: null,
      //   allowedRoles: [Role.ASSET_OPERATOR],
      // },
    ],
  },
  {
    name: i18n.global.t('sidebar.access'),
    items: [
      {
        name: i18n.global.t('organisationsAndUsers'),
        path: `/${ORGANISATIONS_AND_USERS}`,
        featureName: null,
        allowedRoles: [],
      },
      {
        name: i18n.global.t('rfidTags'),
        path: `/${RFID_TAGS}`,
        featureName: Feature.WHITELIST,
        allowedRoles: [Role.WHITELIST_VIEWER],
      },
      {
        name: i18n.global.t('whitelists'),
        path: `/${WHITELISTS}`,
        featureName: Feature.WHITELIST,
        allowedRoles: [Role.WHITELIST_VIEWER],
      },
      {
        name: i18n.global.t('tariffs'),
        path: `/${TARIFFS}`,
        featureName: Feature.TARIFFS,
        allowedRoles: [Role.TARIFF_ADMIN],
      },
    ],
  },
  {
    name: i18n.global.t('sidebar.admin'),
    items: [
      {
        name: i18n.global.t('update.firmware'),
        path: `/${UPDATES}`,
        featureName: Feature.ADMINISTRATION,
        allowedRoles: [Role.UPDATE_ADMIN, Role.ASSET_OPERATOR],
      },
      {
        name: i18n.global.t('dataExport.title'),
        path: `/${DATA_EXPORTS}`,
        featureName: Feature.DATA_EXPORTS,
        allowedRoles: [Role.ASSET_VIEWER],
      },
    ],
  },
  {
    name: i18n.global.t('sidebar.alertsAndReports'),
    items: [
      {
        name: i18n.global.t('monitoring.alerts'),
        path: `/${ALERTS}`,
        featureName: Feature.ACTIVE_MONITORING,
        allowedRoles: [Role.ACTIVE_MONITORING_ADMIN],
      },
      {
        name: i18n.global.t('reports'),
        path: `/${REPORTS}`,
        featureName: Feature.REPORTS,
        allowedRoles: [Role.REPORT_ADMIN],
      },
      {
        name: i18n.global.t('contacts'),
        path: `/${CONTACTS}`,
        featureName: Feature.ACTIVE_MONITORING,
        allowedRoles: [Role.ACTIVE_MONITORING_ADMIN],
      },
    ],
  },
  {
    name: i18n.global.t('sidebar.brandingAndContent'),
    items: [
      {
        name: i18n.global.t('mediaLibrary.title'),
        path: `/${MEDIA_LIBRARY}`,
        featureName: Feature.CONTENT_MANAGEMENT,
        allowedRoles: [Role.CONTENT_ADMIN],
      },
      {
        name: i18n.global.t('playlists.title'),
        path: `/${PLAYLISTS}`,
        featureName: Feature.CONTENT_MANAGEMENT,
        allowedRoles: [Role.CONTENT_ADMIN],
      },
      {
        name: i18n.global.t('branding.title'),
        path: `/${BRANDING}`,
        featureName: Feature.CUSTOM_UI,
        allowedRoles: [Role.CONTENT_ADMIN],
      },
    ],
  },
];
