<template>
  <div class="command-header">
    <widget-label :label="label" :description="props.command.description" class="label-container" />
    <div class="action-container">
      <Button
        :label="t('dashboard.controlPanel.command.actions.execute')"
        class="p-button-sm p-button-footer p-button-primary flex w-full"
        :disabled="disabled"
        @click="() => apply(false)"
        :data-cy="`${props.command.path}-execute-button`"
        :loading="executionRequested"
      />
    </div>
  </div>

  <div
    v-if="executionRequestConflict !== null"
    class="execution-conflict-container"
    :data-cy="`${props.command.path}-execute-conflict-dialog`"
  >
    <div class="execution-conflict-header">
      <exclamation-triangle-icon class="warning-icon" />
      <span>{{ t('dashboard.controlPanel.command.actions.warning') }}</span>
    </div>

    <template v-if="executionRequestConflict.existingCommand">
      <span :data-cy="`${props.command.path}-conflict-message-existingCommand`">{{
        t('dashboard.controlPanel.command.conflictMessages.existingCommand', {
          commandId: executionRequestConflict.existingCommand,
        })
      }}</span>
    </template>

    <template v-if="executionRequestConflict.systemOffline">
      <span :data-cy="`${props.command.path}-conflict-message-systemOffline`">{{
        t('dashboard.controlPanel.command.conflictMessages.systemOffline')
      }}</span>
    </template>

    <div class="command-action-container">
      <Button
        :label="t('cancel')"
        class="p-button-sm p-button-footer p-button-white flex grow whitespace-nowrap"
        @click="cancelApply"
        :disabled="executionRequested"
      />
      <Button
        :label="t('dashboard.controlPanel.command.actions.force')"
        class="p-button-sm p-button-footer p-button-primary flex grow whitespace-nowrap"
        @click="() => apply(true)"
        :data-cy="`${props.command.path}-force-execute-button`"
        :loading="executionRequested"
      />
    </div>
  </div>
  <Toast data-cy="command-status-toast" position="top-center" :group="TOAST_GROUP" />
</template>

<script setup lang="ts">
import type { CommandWidget } from '@/models/controlPanel/dashboard';
import { prettify } from '@/utils/textFormatting';
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import { useControlPanelStore } from '@/stores/admin/controlPanel/controlPanel.store';
import type { ExecutionRequestResult } from '@/stores/admin/controlPanel/controlPanel.api';
import { ExclamationTriangleIcon } from '@heroicons/vue/20/solid';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { DEFAULT_TOAST_LIFE_MILLISECONDS } from '@/utils/constants';
import WidgetLabel from '@/components/hardwareSystems/controlPanel/widgets/common/WidgetLabel.vue';

const props = defineProps<{ command: CommandWidget }>();
const { t } = useI18n();

const TOAST_GROUP = `command-toast-${props.command.path}`;
const toasts = useToast();

const label = props.command.label || prettify(props.command.path.split('.').pop() || '');
const controlPanelStore = useControlPanelStore();

const executionRequested = ref(false);
const executionRequestConflict = ref<ExecutionRequestResult | null>(null);

const handleError = (error: ExecutionRequestResult | Error | undefined) => {
  if (error === undefined) {
    return;
  }

  if (error instanceof Error) {
    showFailedToast(error.message);
    return;
  }

  if (error?.systemOffline !== undefined || error?.existingCommand !== undefined) {
    executionRequestConflict.value = error;
  }
};

const disabled = computed(() => {
  return executionRequestConflict.value !== null || !props.command.writable;
});

const clearErrors = () => {
  executionRequestConflict.value = null;
};

function showFailedToast(details?: string) {
  toasts.removeGroup(TOAST_GROUP);
  toasts.add({
    severity: 'error',
    summary: `Error scheduling command "${label}" for execution`,
    group: TOAST_GROUP,
    detail: details,
    closable: true,
  });
}

function showSuccessToast() {
  toasts.removeGroup(TOAST_GROUP);
  toasts.add({
    severity: 'success',
    summary: `Successfully scheduled command "${label}" for execution`,
    group: TOAST_GROUP,
    life: DEFAULT_TOAST_LIFE_MILLISECONDS,
  });
}

const apply = async (force: boolean) => {
  executionRequested.value = true;
  controlPanelStore
    .executeCommand(props.command.path, force, props.command.arguments)
    .then(clearErrors)
    .then(showSuccessToast)
    .catch(handleError)
    .finally(() => (executionRequested.value = false));
};

const cancelApply = async () => {
  executionRequestConflict.value = null;
};
</script>

<style lang="scss" scoped>
div.command-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  margin: 0.5rem;
  box-sizing: border-box;
}

.execution-conflict-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  margin: 0.5rem;
  box-sizing: border-box;

  gap: 1rem;

  border: 1px solid var(--gray-200);
  border-radius: var(--rounded-md);
  color: var(--gray-600);

  padding: 1rem;
}

.execution-conflict-header {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}

.warning-icon {
  color: var(--orange-primary);
  height: 1.5rem;
  width: 1.5rem;
}

button {
  padding: 0.25rem 0.5rem;
  border-radius: var(--rounded-md);
  border: none;
  cursor: pointer;
}

.command-action-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.generic-error {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;

  margin: 0.5rem;
  box-sizing: border-box;

  color: var(--gray-0);
  background-color: var(--red-primary);
  border-radius: var(--rounded-md);

  padding: 0.5rem;
}

.generic-error-icon {
  height: 1.5rem;
  width: 1.5rem;
}
</style>
