<template>
  <a
    v-if="props.link"
    :href="`/hardware-systems/${props.link}`"
    class="flex items-center justify-center rounded-md text-center px-3 py-2 xl:px-4 xl:py-4 min-w-max text-inherit"
    :style="getTagBackgroundColor(props.color)"
  >
    {{ props.label }}: {{ props.value ? props.value : '--' }}
    {{ props.label === 'Total Energy' ? 'kWh' : '' }}
  </a>
  <div
    v-else
    class="flex items-center justify-center rounded-md text-center px-3 py-2 xl:px-4 xl:py-4 min-w-max"
    :style="getTagBackgroundColor(props.color)"
  >
    {{ props.label }}: {{ props.value ? props.value : '--' }}
    {{ props.label === 'Total Energy' ? 'kWh' : '' }}
  </div>
</template>

<script setup lang="ts">
import { BysColor } from '@/utils/colors';

const props = defineProps<{
  label: string;
  value?: number | string;
  color: string;
  link?: number;
}>();

const getTagBackgroundColor = (color: string) => {
  let colorHex = new BysColor('black').hexString();
  if (color !== 'black' && color) colorHex = new BysColor(color).hexString();

  return `background-color: ${colorHex};`;
};
</script>
