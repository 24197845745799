import { defineStore } from 'pinia';
import {
  fetchChargingSession,
  fetchChargingSessions,
  fetchChargingSessionsKPIs,
  fetchSingleChargingSessionKPIsBySystemId,
  fetchSingleChargingSessionKPIsByChargeParkId,
  fetchChargingSessionStatus,
} from './chargingSessions.api';
import type {
  ChargeParkQueryParams,
  ChargingSession,
  ChargingSessionDetails,
  ChargingSessionsKPIs,
  ChargingSessionMeta,
  ChargingSessionStatus,
} from '@/models/chargingSessions.model';

interface ChargingSessionsState {
  singleChargingSession: ChargingSessionDetails;
  chargingSessions: ChargingSession[];
  chargingSessionsMeta: ChargingSessionMeta;
  chargingSessionsKPIs: ChargingSessionsKPIs;
  chargingSessionStatus: ChargingSessionStatus;
  isFetchingData: boolean;
}

export const useChargingSessionsStore = defineStore('chargingSessions', {
  state: (): ChargingSessionsState => ({
    singleChargingSession: {
      id: 0,
      session_id: '',
      system_id: 0,
      system_name: '',
      charge_park_name: '',
      charge_park_id: 0,
      connector_id: 0,
      session_start: '',
      session_end: '',
      start_soc_percent: 0,
      end_soc_percent: 0,
      energy_kwh: 0,
      reason_session_end: '',
      duration: '',
      peak_power_kw: 0,
      power_lower_than_requested_percent: 0,
      evse_id: null,
      mac_car: '',
      evsp: '',
      average_power: '',
      payment_method: '',
      payment_other: '',
      status: 0,
      transaction_id: null,
    },
    chargingSessions: [],
    chargingSessionsMeta: {
      count: 0,
      next: null,
      previous: null,
    },
    chargingSessionsKPIs: {
      number_of_sessions: 0,
      sessions_with_error: 0,
      total_energy_kwh: 0,
      top_performer: {
        id: 0,
        name: '',
        custom_id: '',
        nidec_id: '',
      },
    },
    chargingSessionStatus: [{ id: 0, name: '' }],
    isFetchingData: false,
  }),
  actions: {
    async fetchChargingSessions(params: ChargeParkQueryParams) {
      this.isFetchingData = true;

      return await fetchChargingSessions(params)
        .then((response) => {
          const { count, previous, next, results } = response.data;
          this.chargingSessions = results;
          this.chargingSessionsMeta = { count, previous, next };
          this.isFetchingData = false;
          return response;
        })
        .finally(() => (this.isFetchingData = false));
    },
    async fetchChargingSession(chargingSessionID: ChargingSessionDetails['id']) {
      this.isFetchingData = true;

      return await fetchChargingSession(chargingSessionID)
        .then((response) => {
          const result = response.data;
          this.singleChargingSession = result;
          this.isFetchingData = false;
          return response;
        })
        .finally(() => (this.isFetchingData = false));
    },
    async fetchChargingSessionsKPIs() {
      this.isFetchingData = true;

      return await fetchChargingSessionsKPIs()
        .then((response) => {
          const result = response.data;
          this.chargingSessionsKPIs = result;
          this.isFetchingData = false;
          return response;
        })
        .finally(() => (this.isFetchingData = false));
    },
    async fetchSingleChargingSessionKPIsBySystemId(
      chargingSessionSystemID: ChargingSessionDetails['system_id']
    ) {
      this.isFetchingData = true;

      return await fetchSingleChargingSessionKPIsBySystemId(chargingSessionSystemID)
        .then((response) => {
          const result = response.data;
          this.chargingSessionsKPIs = result;
          this.isFetchingData = false;
          return response;
        })
        .finally(() => (this.isFetchingData = false));
    },
    async fetchSingleChargingSessionKPIsByChargeParkId(
      chargingSessionChargeParkId: ChargingSessionDetails['charge_park_id']
    ) {
      this.isFetchingData = true;

      return await fetchSingleChargingSessionKPIsByChargeParkId(chargingSessionChargeParkId)
        .then((response) => {
          const result = response.data;
          this.chargingSessionsKPIs = result;
          this.isFetchingData = false;
          return response;
        })
        .finally(() => (this.isFetchingData = false));
    },
    async fetchChargingSessionStatus() {
      this.isFetchingData = true;

      return await fetchChargingSessionStatus()
        .then((response) => {
          const result = response.data;
          this.chargingSessionStatus = result;
          this.isFetchingData = false;
          return response;
        })
        .finally(() => (this.isFetchingData = false));
    },
  },
  getters: {
    getStatus(state): number {
      return state.singleChargingSession.status;
    },
    getStatusID(state): number | undefined {
      const statusItem = state.chargingSessionStatus.find((item) => item.id === this.getStatus);
      return statusItem?.id;
    },
    getStatusName(state): string {
      const statusItem = state.chargingSessionStatus.find((item) => item.id === this.getStatus);
      return statusItem ? statusItem.name : '--';
    },
  },
});
