<template>
  <div class="system-info flex items-center">
    <img
      v-if="productType?.image_url"
      class="max-w-14 max-h-14 object-contain"
      :src="productType?.image_url"
      alt="System Image"
    />
    <div v-else class="">
      <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
    </div>
    <div class="flex flex-col">
      <div class="system-name">
        <div class="name-container">
          <h2 v-if="system?.name">{{ system?.name }}</h2>
          <h2 v-else>{{ $t('hardwareSystem.unnamedAsset') }}{{ system?.nidec_id }}</h2>

          <i class="pi pi-spin pi-spinner" v-if="props.status === 'loading'" />
          <check-circle-icon
            style="height: 1rem"
            v-else-if="props.status === 'success'"
            class="success"
          />
          <exclamation-triangle-icon
            style="height: 1rem"
            v-else-if="props.status === 'error'"
            class="error"
          />
          <div
            class="status-inducator flex flex-row ml-2"
            :class="{ online: system?.is_bys_connection_online }"
            v-tooltip.right="
              `${
                system?.last_online_at
                  ? formatDateTimeWithUsersTimezone(system.last_online_at)
                  : $t('hardwareSystem.noData')
              }`
            "
          >
            <span
              data-cy="system-connection-online-status"
              class="mr-2"
              :class="system?.is_bys_connection_online ? 'text-gray-200' : 'text-gray-400'"
            >
              {{ $t('hardwareSystem.lastOnline') }}
            </span>

            <date-time-display
              v-if="system?.last_online_at"
              class="text-xl"
              :date="system.last_online_at"
              relative
              :style="system?.is_bys_connection_online ? `color: white !important` : ``"
            />
            <span v-else class="text-gray-800">{{ $t('never') }}</span>
            <span class="pi pi-info-circle text-md ml-2 relative top-[2px]"></span>
          </div>
        </div>
      </div>
      <control-panel-info-grid
        class="ml-3 mt-1"
        :product-type="productType"
        :custom-id="system?.custom_id"
        :firmware-version="system?.version"
        :last-online="system?.last_online_at"
        :nidec-id="system?.nidec_id"
        @go-to-firmware="emits('go-to-firmware')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import ControlPanelInfoGrid from '@/components/hardwareSystems/general/info/ControlPanelInfoGrid.vue';

import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/vue/20/solid';
import { formatDateTimeWithUsersTimezone } from '@/utils/dateTimeFormatVue';
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';
import type { ProductType } from '@/models/productTypes.model';
import type { HardwareSystem } from '@/models/hardwareSystems.model';
const emits = defineEmits<{
  (event: 'go-to-firmware'): void;
}>();
const props = defineProps<{
  system: HardwareSystem | null;
  productType: ProductType | null;
  status?: string;
}>();
</script>

<style lang="scss" scoped>
.system-name {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;
}

.name-container {
  display: flex;
  align-items: center;

  h2 {
    display: inline-block;
    color: var(--gray-800);
    font-size: 1.5rem;
    margin-right: 1rem;
  }

  div {
    display: inline-block;
    padding: 0.1rem 0.75rem;
    border-radius: var(--rounded-xl);
    font-size: 0.9rem;
    background-color: var(--gray-200);
    color: var(--gray-400);

    &.online {
      background-color: var(--green-primary);
      color: var(--gray-0);
    }
  }
}
.success {
  color: var(--green-primary);
}

.error {
  color: var(--red-primary);
}
</style>
