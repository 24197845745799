<template>
  <div v-if="isLoading" class="h-full w-full flex items-center justify-center">
    <div class="spinner" />
  </div>

  <error-bar
    v-else-if="initError"
    title="Failed to load control panel"
    :message="`Failed to load control panel for system ${systemId}`"
  />

  <div v-else class="panel-wrapper">
    <div class="bg-white border-bottom border-neutral-200">
      <div class="flex items-center justify-between">
        <hardware-system-image-name
          :system="system"
          :product-type="controlPanelStore.productType"
          :status="status"
          @go-to-firmware="activeTab = 'updates'"
        />

        <div v-if="system && system.number_of_connectors > 0" class="flex flex-row">
          <div class="flex items-center justify-center pr-4">
            {{ t('hardwareSystem.connectorStatus') }}
          </div>
          <HardwareSystemConnectorStates
            :data-cy="`connectorStatus-system-${system.id}`"
            class="mr-5"
            :connectorStates="system.connector_state"
            :numberOfConnectors="system.number_of_connectors"
          >
          </HardwareSystemConnectorStates>
        </div>
      </div>
      <control-panel-tabs
        class="tabs-container"
        :warned-tabs="warnedTabs"
        :tab-groups="tabGroups"
        :active-tab="activeTab"
        @update:activeTab="(tab) => (activeTab = tab)"
      />
    </div>

    <div v-if="activeTab === 'general'" class="scrollable">
      <hardware-system-general v-if="!isLoading && system" />
    </div>

    <div v-if="activeTab === 'charging-sessions'" class="scrollable">
      <ChargingSessions v-if="system?.id" :system_id="system?.id"></ChargingSessions>
    </div>

    <logs-view v-else-if="activeTab === 'logs'" :system-id="system?.id" />

    <div v-else-if="activeTab === 'event-history'" class="scrollable">
      <event-history v-if="system" :system="system" />
    </div>

    <div v-else-if="activeTab === 'active-errors'" class="scrollable">
      <active-errors v-if="system" :system="system" />
    </div>

    <div v-else-if="activeTab === 'updates'" class="scrollable">
      <hardware-system-update-table v-if="system" :system="system" />
    </div>

    <div v-else-if="activeTab === 'status'" class="scrollable">
      <control-panel-status :system="system" />
    </div>

    <div v-else-if="activeTab === 'settings'" class="scrollable">
      <control-panel-settings :system="system" :product-type="controlPanelStore.productType" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onBeforeUnmount, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useControlPanelStore } from '@/stores/admin/controlPanel/controlPanel.store';
import { useUsersStore } from '@/stores/admin/users/users.store';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';

import EventHistory from '@/components/eventHistory/EventHistory.vue';
import ActiveErrors from '@/components/eventHistory/ActiveErrors.vue';
import HardwareSystemUpdateTable from '@/components/hardwareSystems/HardwareSystemUpdateTable.vue';
import HardwareSystemGeneral from '@/components/hardwareSystems/general/HardwareSystemGeneral.vue';
import HardwareSystemConnectorStates from '@/components/hardwareSystems/HardwareSystemConnectorStates.vue';
import ErrorBar from '@/components/common/bar/ErrorBar.vue';

import type { HardwareSystem } from '@/models/hardwareSystems.model';
import type { ProductType } from '@/models/productTypes.model';
import type { TabGroups } from '@/@types';
import LogsView from '@/components/logs/LogsView.vue';
import HardwareSystemImageName from '@/components/hardwareSystems/general/info/HardwareSystemImageName.vue'; 
import ControlPanelTabs from '@/components/hardwareSystems/general/navigation/ControlPanelTabs.vue';
import ControlPanelStatus from '@/components/hardwareSystems/controlPanel/ControlPanelStatus.vue';
import ControlPanelSettings from '@/components/hardwareSystems/controlPanel/ControlPanelSettings.vue'; 
import ChargingSessions from '@/components/chargingSessions/ChargingSessions.vue';

const route = useRoute();
const controlPanelStore = useControlPanelStore();
const { fetchUserData } = useUsersStore();
const systemStore = useHardwareSystemsStore();
const { singleSystem } = storeToRefs(systemStore);

const { t } = useI18n();
const status = ref('loading');
const warnedTabs = computed(() => {
  if (systemStore.activeErrors.length > 0) {
    return ['active-errors'];
  }
  return [];
});
// Your tabGroups array
const tabGroups: TabGroups = [
  [
    { id: 'general', label: t('dashboard.controlPanel.tabs.general') },
    
    { id: 'charging-sessions', label: t('dashboard.controlPanel.tabs.chargingSessions') },
    { id: 'updates', label: t('dashboard.controlPanel.tabs.firmware') },
    { id: 'event-history', label: t('dashboard.controlPanel.tabs.events') },

    { id: 'logs', label: t('dashboard.controlPanel.tabs.logs') },
  ],
  [
    { id: 'status', label: t('dashboard.controlPanel.tabs.status') },
    { id: 'settings', label: t('dashboard.controlPanel.tabs.settings') },
  ],
];

let activeTab = ref('general');

const isLoading = ref(true);
const initError = ref(false);

const system = ref<HardwareSystem | null>(null);

let productType: ProductType | null = null;

const systemId = Number(route.params.id);
const refreshIntervalSeconds = 15;
const refreshCounter = ref(refreshIntervalSeconds);
watch(
  () => singleSystem.value,
  (value) => {
    system.value = value;
  }
);
let refreshInt: NodeJS.Timeout | undefined;
const refresh = async () => {
  status.value = 'loading';

  await Promise.all([
    systemStore.fetchSingleSystem(systemId),
    systemStore.fetchActiveErrors(systemId),
  ]);

  status.value = 'success';
  system.value = singleSystem.value;

  let versionMatch = system.value.version?.match(/(\d+)\.(\d+)\.(\d+)/);
  if (versionMatch && !tabGroups[0].find(item=>item.id==='active-errors')) {
    let [_, major, minor, patch] = versionMatch;
    if (Number(major) > 2 || (Number(major) == 2 && Number(patch) >= 198)) {
      tabGroups[0].splice(3, 0, {
        id: 'active-errors',
        label: t('dashboard.controlPanel.tabs.activeErrors'),
      });
    }
  }
};

const refreshLoop = async () => {
  if (refreshCounter.value === 0) {
    await refresh();
    refreshCounter.value = refreshIntervalSeconds;
  }
  refreshCounter.value--;
  refreshInt = setTimeout(refreshLoop, 1000);
};

onMounted(async () => {
  try {
    const tasks = []
    if (systemStore.connectorStateTypes.length == 0) tasks.push(systemStore.fetchConnectorStateTypes());
    tasks.push(controlPanelStore.initialize(systemId));
    tasks.push(refresh());
    await Promise.all(tasks)
  } catch (e) {
    initError.value = true;
  } finally {
    productType = controlPanelStore.productType; 
    isLoading.value = false;
    refreshLoop();
  }

  fetchUserData().then();
});

onBeforeUnmount(() => {
  controlPanelStore.destroy();
  clearTimeout(refreshInt);
});
</script>

<style lang="scss" scoped>
div.status-bar {
  width: 100%;
  background-color: var(--gray-100);
  color: var(--gray-400);
  padding: 0.5rem 1rem;

  &.online {
    background-color: var(--green-primary);
    color: var(--gray-0);
  }
}

div.panel-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

div.system-info {
  background-color: var(--gray-0);
  padding: 1rem;
}

.system-details {
  max-width: 550px;
}

div.tabs-container {
  width: 100%;
}

.scrollable {
  height: 100%;
  overflow: auto;
}

.status-indicator {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 1;
}

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: var(--gray-100);
  border-bottom: 1px solid var(--gray-200);
  padding: 0.5rem 1rem;
}

div.spinner {
  height: 2rem;
  width: 2rem;
  border: 2px solid var(--gray-200);
  border-top-color: var(--gray-400);
  animation: spin 1s linear infinite;
  border-radius: 50%;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.icon-container {
  height: 1.1rem;
  width: 1.1rem;
  font-size: 1rem;
  line-height: 1rem;
  color: var(--gray-400);
}
</style>
