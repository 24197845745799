export const DASHBOARD = 'dashboard';
export const ORGANISATIONS_AND_USERS = 'organisations-and-users';
export const CHARGE_PARKS = 'charge-parks';
export const HARDWARE_SYSTEMS = 'hardware-systems';
export const DOWNTIMES = 'unplanned-outages';
export const RFID_TAGS = 'rfid-tags';
export const WHITELISTS = 'whitelists';
export const UPDATES = 'updates';
export const ALERTS = 'alerts';
export const REPORTS = 'reports';
export const CONTACTS = 'contacts';
export const MEDIA_LIBRARY = 'media-library';
export const PLAYLISTS = 'playlists';
export const USER_PREFERENCES = 'user-preferences';
export const TARIFFS = 'tariffs';
export const BRANDING = 'branding';
export const DATA_EXPORTS = 'data-exports';
export const CHARGING_SESSIONS = 'charging-sessions';

// AUTH

export enum ROUTES {
  SIGN_IN = 'login',
  OPENID_CALLBACK = 'openid-callback',
  AUTH_ERROR = 'auth-error',
  SIGN_UP = 'signup',
  DPA = 'dpa',
  AUTH_TEMPLATE = 'static/auth/index.html',
}
