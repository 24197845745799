import { Color, type RGBA } from '@kurkle/color';

export const colorsPallette = ['#789342', '#76C1C3', '#548D95', '#ECC199', '#CBDBA7'];

export class BysColor extends Color {
  constructor(input: Color | RGBA | string | number[]) {
    if (input.toString() === 'blue') super('#75bef8');
    else super(new Color(input).alpha(0.45));
  }
}
