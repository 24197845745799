<template>
  <Button
    outlined
    class="font-medium p-2 pr-3"
    data-cy="back-button"
    icon="pi pi-angle-left"
    :label="$t('back')"
    @click="btnPressed"
  />
</template>

<script setup lang="ts">
import Button from 'primevue/button';
import { useRouter } from 'vue-router';

const router = useRouter();

const props = defineProps<{ 
  overrideBackButtonFunction?: Function;
}>();

const btnPressed = ()=>{
  if(props.overrideBackButtonFunction) {
    props.overrideBackButtonFunction();
  } else {
    goBack()
  }
}

const goBack = () => router.back();
</script>
