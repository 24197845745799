import type {
  ChargeParkQueryParams,
  ChargingSession,
  ChargingSessionDetails,
  ChargingSessionsKPIs,
  ChargingSessionStatus,
} from '@/models/chargingSessions.model';
import type { AxiosPromise, AxiosResponse } from 'axios';
import axios from 'axios';

export async function fetchChargingSessions(
  queryParams: ChargeParkQueryParams
): Promise<AxiosResponse> {
  try {
    return await axios.get<AxiosPromise>(`/api/charging-sessions/`, {
      params: {
        page_size: queryParams.pageSize,
        page: queryParams.pageNumber,
        ordering: queryParams.ordering,
        search: queryParams.search,
        session_id: queryParams.session_id,
        system_id: queryParams.system_id,
        charge_park_id: queryParams.charge_park_id,
        evse_id: queryParams.evse_id,
        session_start: queryParams.session_start,
        session_start_greater_than: queryParams.session_start_greater_than,
        session_end: queryParams.session_end,
        session_end_less_than: queryParams.session_end_less_than,
        energy_kwh: queryParams.energy_kwh,
        energy_kwh_less_than: queryParams.energy_kwh_less_than,
        energy_kwh_greater_than: queryParams.energy_kwh_greater_than,
        peak_power_kw: queryParams.peak_power_kw,
        peak_power_kw_less_than: queryParams.peak_power_kw_less_than,
        peak_power_kw_greater_than: queryParams.peak_power_kw_greater_than,
        duration: queryParams.duration,
        duration_less_than: queryParams.duration_less_than,
        duration_greater_than: queryParams.duration_greater_than,
        start_soc_percent: queryParams.start_soc_percent,
        end_soc_percent: queryParams.end_soc_percent,
        power_lower_than_requested_percent: queryParams.power_lower_than_requested_percent,
        power_lower_than_requested_percent_less_than:
          queryParams.power_lower_than_requested_percent_less_than,
        power_lower_than_requested_percent_greater_than:
          queryParams.power_lower_than_requested_percent_greater_than,
      },
    });
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchChargingSession(
  chargingSessionID: ChargingSessionDetails['id']
): Promise<AxiosResponse> {
  try {
    return await axios.get<AxiosPromise>(`/api/charging-sessions/${chargingSessionID}/`);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchChargingSessionsKPIs(): Promise<AxiosResponse> {
  return axios.get<AxiosPromise>(`/api/charging-sessions/kpi/`);
}

export async function fetchSingleChargingSessionKPIsBySystemId(
  chargingSessionSystemID: ChargingSessionDetails['system_id']
): Promise<AxiosResponse> {
  return axios.get<AxiosPromise>(
    `/api/charging-sessions/kpi/?system_id=${chargingSessionSystemID}`
  );
}

export async function fetchSingleChargingSessionKPIsByChargeParkId(
  chargingSessionSystemID: ChargingSessionDetails['charge_park_id']
): Promise<AxiosResponse> {
  return axios.get<AxiosPromise>(
    `/api/charging-sessions/kpi/?charge_park_id=${chargingSessionSystemID}`
  );
}

export async function fetchChargingSessionStatus(): Promise<AxiosResponse> {
  return axios.get<AxiosPromise>(`/api/charging-session-status/`);
}
