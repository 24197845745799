<template>
  <page-header
    :title="t('chargingSessions.title')"
    :overrideBackButtonFunction="backBtnPressed"
    show-back-button
  />
  <div class="container container-grey grid grid-cols-1 min-[1300px]:grid-cols-2 gap-2">
    <Panel :header="t('chargingSessions.chargingSession')">
      <div class="flex flex-col w-full md:divide-solid md:divide-y">
        <div class="grid md:grid-cols-2 gap-2 md:gap-6">
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.transaction_id')"
            :value="singleChargingSession.transaction_id"
          ></ChargingSessionDetailItem>
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.sessionID')"
            :value="singleChargingSession.session_id"
          ></ChargingSessionDetailItem>
        </div>

        <div class="grid md:grid-cols-2 gap-2 md:gap-6">
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.system_name')"
            :value="singleChargingSession.system_name"
          ></ChargingSessionDetailItem>
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.asset_serial')"
            :value="sessionsChargerSerial"
          ></ChargingSessionDetailItem>
        </div>

        <ChargingSessionDetailItem
          class="md:col-span-2"
          :title="$t('hardwareSystem.hardwareSystemDetails.custom_id')"
          :value="sessionsChargerInventoryNr"
        ></ChargingSessionDetailItem>

        <div class="grid md:grid-cols-2 md:gap-6">
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.connector_id')"
            :value="singleChargingSession.connector_id"
          ></ChargingSessionDetailItem>
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.evse_id')"
            :value="singleChargingSession.evse_id"
          ></ChargingSessionDetailItem>
        </div>

        <div class="grid md:grid-cols-2 md:gap-6">
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.session_start')"
            :value="singleChargingSession.session_start"
            :tooltip="$t('chargingSessions.tooltip.session_start')"
            isDateTime
          ></ChargingSessionDetailItem>
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.session_end')"
            :value="singleChargingSession.session_end"
            :tooltip="$t('chargingSessions.tooltip.session_start')"
            isDateTime
          ></ChargingSessionDetailItem>
        </div>
        <div class="grid md:grid-cols-2 md:gap-6">
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.duration')"
            :value="relativeDuration"
          ></ChargingSessionDetailItem>

          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.mac_car')"
            :value="singleChargingSession.mac_car"
            breakAll
          ></ChargingSessionDetailItem>
        </div>

        <div class="grid md:grid-cols-2 md:gap-6">
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.payment_method')"
            :value="singleChargingSession.payment_method"
          ></ChargingSessionDetailItem>

          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.payment_other')"
            :value="singleChargingSession.payment_other"
          ></ChargingSessionDetailItem>
        </div>
      </div>
    </Panel>
    <Panel :header="t('chargingSessions.powerAndEnergy')">
      <div class="flex flex-col w-full md:divide-solid md:divide-y">
        <div class="grid md:grid-cols-2 md:gap-6">
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.start_soc_percent')"
            :value="
              singleChargingSession.start_soc_percent !== null
                ? singleChargingSession.start_soc_percent.toFixed(2)
                : null
            "
            unit-suffix="%"
          ></ChargingSessionDetailItem>
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.end_soc_percent')"
            :value="
              singleChargingSession.end_soc_percent !== null
                ? singleChargingSession.end_soc_percent.toFixed(2)
                : null
            "
            unit-suffix="%"
          ></ChargingSessionDetailItem>
        </div>

        <div class="grid md:grid-cols-2 md:gap-6">
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.energy_kwh')"
            :value="singleChargingSession.energy_kwh.toFixed(4)"
            unit-suffix="kWh"
          ></ChargingSessionDetailItem>
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.peak_power_kw')"
            :value="
              singleChargingSession.peak_power_kw !== null
                ? singleChargingSession.peak_power_kw.toFixed(0)
                : null
            "
            unit-suffix="kW"
          ></ChargingSessionDetailItem>
        </div>
        <div class="grid md:grid-cols-2 md:gap-6">
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.average_power')"
            :value="
              singleChargingSession.average_power !== null
                ? parseFloat(singleChargingSession.average_power).toFixed(0)
                : null
            "
            unit-suffix="kW"
          ></ChargingSessionDetailItem>
          <ChargingSessionDetailItem
            :title="$t('chargingSessions.details.power_lower_than_requested_percent')"
            :value="
              singleChargingSession.power_lower_than_requested_percent !== null
                ? singleChargingSession.power_lower_than_requested_percent.toFixed(2)
                : null
            "
            :tooltip="$t('chargingSessions.tooltip.power_lower_than_requested_percent')"
            unit-suffix="%"
          ></ChargingSessionDetailItem>
        </div>
        <ChargingSessionDetailItem
          class="md:col-span-2"
          :title="$t('chargingSessions.details.evsp')"
          :value="singleChargingSession.evsp"
          breakAll
        ></ChargingSessionDetailItem>

        <ChargingSessionDetailItem
          class="md:col-span-2"
          :title="$t('chargingSessions.details.status')"
          :value="chargingSessionStore.getStatusName"
        ></ChargingSessionDetailItem>

        <ChargingSessionDetailItem
          class="md:col-span-2"
          :title="$t('chargingSessions.details.reason_session_end')"
          :value="singleChargingSession.reason_session_end"
        ></ChargingSessionDetailItem>
      </div>
    </Panel>
    <MultiTelemetryWidget
      v-if="singleChargingSession.system_id"
      class="col-span-1 min-[1300px]:col-span-2"
      :chargePointId="singleChargingSession.connector_id - 1"
      :systemId="singleChargingSession.system_id"
      :startTime="singleChargingSession.session_start"
      :endTime="singleChargingSession.session_end"
    ></MultiTelemetryWidget>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';

import Panel from 'primevue/panel';
import { useI18n } from 'vue-i18n';

import { getDurationFromSec } from '@/utils/dateTimeFormat';
import { useChargingSessionsStore } from '@/stores/admin/chargingSessions/chargingSessions.store';
import ChargingSessionDetailItem from './ChargingSessionDetailItem.vue';
import PageHeader from '@/components/common/layout/PageHeader.vue';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';

import MultiTelemetryWidget from '../hardwareSystems/controlPanel/widgets/telemetry/MultiTelemetryWidget.vue';
const { t } = useI18n();
const route = useRoute();

const emit = defineEmits(['closeDetailsPage']);
const systemStore = useHardwareSystemsStore();

const chargingSessionStore = useChargingSessionsStore();
const relativeDuration = computed(
  () =>
    getDurationFromSec(singleChargingSession.value.duration) +
    ` (${singleChargingSession.value.duration} ${t('seconds')})`
);
const props = defineProps<{
  sessionID: number;
}>();

const { singleChargingSession } = storeToRefs(chargingSessionStore);
const { singleSystem } = storeToRefs(systemStore);

const sessionsChargerInventoryNr = ref<string>('');
const sessionsChargerSerial = ref<string>('');
const backBtnPressed = () => {
  emit('closeDetailsPage');
};

onMounted(async () => {
  chargingSessionStore.fetchChargingSession(props.sessionID).then(async () => {
    if (singleChargingSession.value.system_id > 0) {
      await systemStore.fetchSingleSystem(singleChargingSession.value.system_id);
      sessionsChargerInventoryNr.value = singleSystem.value.custom_id;
      sessionsChargerSerial.value = singleSystem.value.nidec_id;
    }
  });

  chargingSessionStore.fetchChargingSessionStatus();
});

onUnmounted(() => {
  sessionsChargerInventoryNr.value = '';
  sessionsChargerSerial.value = '';
});
</script>
<style scoped lang="scss">
.p-panel {
  box-shadow: none;
  border: 1px solid $grey-medium-light;

  &.no-buttons {
    :deep(.p-panel-header) {
      padding: 18px 16px;
    }
  }
}
:deep(.p-panel-header) {
  padding: 16px 16px;
  background-color: $grey-light;
  border-bottom: 1px solid $grey-medium-light;
}

:deep(.p-panel-content) {
  padding: 4px 16px;
}

:deep(.p-panel-title) {
  font-size: 16px;
  color: $text-dark;
}
</style>
