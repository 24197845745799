<template>
  <div
    class="flex justify-between align-items-center p-5 text-sm lg:text-lg xl:text-xl overflow-x-auto"
  >
    <div class="flex items-center justify-center pr-10 min-w-max">
      {{ $t('chargingSessions.chargingSessionTotals') }}
    </div>
    <div class="flex gap-3 items-center">
      <TableHeaderTag
        label="Number of Sessions"
        :value="chargingSessionsStore.chargingSessionsKPIs.number_of_sessions"
        color="grey"
      />
      <TableHeaderTag
        label="Sessions with Error"
        :value="chargingSessionsStore.chargingSessionsKPIs.sessions_with_error"
        color="red"
      />
      <TableHeaderTag
        label="Total Energy"
        :value="chargingSessionsStore.chargingSessionsKPIs.total_energy_kwh.toFixed(2)"
        color="blue"
      />
      <TableHeaderTag
        v-if="!props.system_id"
        label="Top Performer"
        :value="chargingSessionsStore.chargingSessionsKPIs.top_performer?.nidec_id"
        color="green"
        :link="chargingSessionsStore.chargingSessionsKPIs.top_performer?.id"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import TableHeaderTag from '@/components/common/TableHeaderTag.vue';
import { useChargingSessionsStore } from '@/stores/admin/chargingSessions/chargingSessions.store';

const props = defineProps<{
  system_id?: number;
  charge_park_id?: number;
}>();

const chargingSessionsStore = useChargingSessionsStore();

onMounted(() => {
  if (props.system_id) {
    chargingSessionsStore.fetchSingleChargingSessionKPIsBySystemId(props.system_id);
  } else if (props.charge_park_id) {
    chargingSessionsStore.fetchSingleChargingSessionKPIsByChargeParkId(props.charge_park_id);
  } else {
    chargingSessionsStore.fetchChargingSessionsKPIs();
  }
});
</script>
