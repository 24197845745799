import axios from 'axios';
import dayjs from 'dayjs';
import type {
  HardwareSystemUpdate,
  HardwareSystem,
  HardwareUpdate,
  AvailableTelemetryOption,
} from '@/models/hardwareSystems.model';
import type { AvailableTelemetryChannelsFilters, EventQueryParams } from '@/models/history.model';
import type { ChargePark } from '@/models/chargeParks.model';
import type { Organisation } from '@/models/organisation.model';
import type { LogFile, LogFileDownloadUrl } from '@/models/logs.model';

export function fetchHardwareSystems() {
  return axios.get(`/api/systems/`);
}

export function fetchHardwareSystem(id: number) {
  return axios.get(`/api/systems/${id}/`);
}

export function delegateHardwareSystem(
  systemId: HardwareSystem['id'],
  organisationId: Organisation['id']
) {
  return axios.post(`/api/systems/${systemId}/delegate_to_organisation/`, {
    owner_organisation_id: organisationId,
  });
}

export function editHardwareSystem(systemId: HardwareSystem['id'], data: HardwareSystemUpdate) {
  return axios.patch(`/api/systems/${systemId}/`, data);
}

export function assignChargePark(systemId: HardwareSystem['id'], chargeParkId: ChargePark['id']) {
  return axios.patch(`/api/systems/${systemId}/`, {
    charge_park_id: chargeParkId,
  });
}

export function unassignChargePark(systemId: HardwareSystem['id']) {
  return axios.patch(`/api/systems/${systemId}/`, {
    charge_park_id: null,
  });
}
export function fetchFilteredHardwareSystems(systemId: HardwareSystem['id']) {
  return axios.get(`/api/systems/?exclude_whitelist=${systemId}`);
}

function setFilenameToLastSegmentOfFilePath(logFile: LogFile) {
  return {
    ...logFile,
    filename: logFile.extracted_filename.split('/').pop() || logFile.filename,
  };
}

export async function fetchLogFiles(
  systemId: HardwareSystem['id'],
  start: string,
  end: string
): Promise<LogFile[]> {
  return axios
    .get<LogFile[]>(`/api/systems/${systemId}/logs/files/?start_time=${start}&end_time=${end}`)
    .then((res) => res.data.map(setFilenameToLastSegmentOfFilePath));
}

export async function fetchLogRaw(
  systemId: HardwareSystem['id'],
  start: string,
  end: string
): Promise<string> {
  return axios
    .get(`/api/systems/${systemId}/logs/raw/?start_time=${start}&end_time=${end}`)
    .then((res) => res.data);
}

export async function fetchLogFileDownloadUrl(logText: string): Promise<LogFileDownloadUrl> {
  return axios.get(`/api/logs/files/${logText}/`).then((res) => res.data);
}

export function updateSystem(systemId: HardwareSystem['id'], data: HardwareUpdate) {
  return axios.post(`/api/systems/${systemId}/updates/`, {
    update_package_id: data.update_package_id,
    retries: data.retries,
    retry_interval: data.retry_interval ? data.retry_interval : null,
    retrieve_date: new Date(dayjs(data.retrieve_date).second(0).format()),
  });
}

export function fetchActiveErrors(systemId: HardwareSystem['id']) {
  return axios.get(`/api/systems/${systemId}/active_errors/`);
}
export function fetchEventHistory(systemId: HardwareSystem['id'], queryParams: EventQueryParams) {
  return axios.get(`/api/systems/${systemId}/history/`, {
    params: {
      page_size: queryParams.pageSize,
      page: queryParams.pageNumber,
      start_time: queryParams.startTime,
      end_time: queryParams.endTime,
      type:
        queryParams.type && queryParams.type.length > 0
          ? queryParams.type.join(',')
          : queryParams.type,
      event: queryParams.event,
      details: queryParams.details,
      ordering: queryParams.ordering,
      aggregate_errors: queryParams.aggregateErrors,
    },
  });
}

export function fetchConnectorStateTypes() {
  return axios.get(`/api/connector-state-types/`);
}

export function fetchEventTypes() {
  return axios.get(`/api/event-types/`);
}

export function fetchConnectorsStatesSeries(id?: ChargePark['id']) {
  let route = `/api/systems/connector-states/`;
  if (id !== undefined) route += `?charge_park=${id}`;
  return axios.get(route);
}

export async function fetchSystemsByChargePark(id: ChargePark['id']): Promise<HardwareSystem[]> {
  return axios.get(`/api/systems/?charge_park=${id}`).then((res) => res.data);
}

export async function fetchAvailableTelemetryChannels(
  id: HardwareSystem['id'],
  queryParams: AvailableTelemetryChannelsFilters
): Promise<AvailableTelemetryOption[]> {
  return axios
    .get(`/api/systems/${id}/available-telemetry-channels/`, {
      params: {
        start_time: queryParams.startTime,
        end_time: queryParams.endTime,
      },
    })
    .then((res) => res.data);
}
